.sidebar {
    font-size: 16px;
    background: #344154;
        @media screen and (max-width: 768px) {
            padding-left: 0px;
            transition: all 0.1s;
        }
    }
    .sidebar-nav .nav-group.show .nav-group-toggle {
        color: #ffffff;
    }
 
    .sidebar-nav .nav-group-toggle::after {
        background-color: #ffffff;
        &:hover {
            background-color: #ffffff;
        }
    }
 
    .sidebar-nav {
        padding: 0px;
    }
 
    .sidebar-nav .nav-link {
        color: #ffffff;
        &:hover {
            background-color: #60bb46;
            color: #ffffff;
        }
    }
 
    // .SidebarLink {
    //     color: #ffffff;
   
    //     &:hover {
    //         background-color: #60bb46;
    //         color: #ffffff;
    //     }
       
    // }
 
    // .DropdownLink {
    //     color: #ffffff;
    //     background-color: #7f50d6;
   
    //     &:hover {
    //         background-color: #1f1f1b;
    //         border-left: 4px solid #60bb46;
    //         color: #ffffff;
    //     }
    // }
 
    .menu-level-1 {
        background-color: #344154;
        color: #ffffff;
 
        &:hover {
            background-color: #60bb46;
        }
      }
      .menu-level-2 {
        background-color: #192638 !important;
        color: #ffffff;
 
        &:hover {
            background-color: #1f1f1b;
            border-left: 2px solid #60bb46;
        }
      }