
.sk-cube-grid {
	width: 40px;
	height: 40px; 
	margin: 100px auto;
  
}

.sk-cube-grid .sk-cube {
	width: 33%; 
	height: 33%; 
	background-color: #ff96ca; 
	float: left; 
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: 0.2s; 
	animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: 0.3s; 
	animation-delay: 0.3s; 
}
.sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: 0.4s; 
	animation-delay: 0.4s; 
}
.sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: 0.1s; 
	animation-delay: 0.1s; 
}
.sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: 0.2s; 
	animation-delay: 0.2s; 
}
.sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: 0.3s; 
	animation-delay: 0.3s; 
}
.sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0s; 
	animation-delay: 0s; 
}
.sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: 0.1s; 
	animation-delay: 0.1s; 
}
.sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: 0.2s; 
	animation-delay: 0.2s; 
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1); 
		transform: scale3D(1, 1, 1); 
	} 35% {
		-webkit-transform: scale3D(0, 0, 1); 
		transform: scale3D(0, 0, 1); 
	}
}

@keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1); 
		transform: scale3D(1, 1, 1);
	} 35% {
		-webkit-transform: scale3D(0, 0, 1); 
		transform: scale3D(0, 0, 1); 
	}
}

		
	 



.full-width-div {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  display: flex;
  /* background: rgba(51, 51, 51, 0.7); */
  z-index: 2000;
}
.spin-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.spin-container {
  height: 100%;
  width: 100%;
  display: table;
}
.spin-inner {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}
.spin-cube-grid {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.spin-cube {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  -webkit-animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
  animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
}
.spin-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.spin-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.spin-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.spin-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.spin-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.spin-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.spin-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.spin-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.spin-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}


@media (max-width: 991.98px)
{
  .app-header {
    width:  100vw;
}
.app-body{
    width:  100vw;
}

}