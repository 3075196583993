@import "../assets/scss/defaultTheme.scss";

.header {
    width: 100%;
    position: fixed;
    z-index: 999;
}
.outer {
   position: relative;
    top: 110px;
    padding: 10px;
}

.test{
    padding-left: 280px;
    @media screen and (max-width: 1200px) {
        padding-left: 220px;
    }

    @media screen and (max-width: 768px) {
        padding-left: 0px;
    }
}


#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }





