@import "../assets/scss/defaultTheme.scss";

#default_breadcrumb_id {
    top: 55px;
    height: 42px;
    width: -webkit-fill-available;
    z-index: 1010;
    position: fixed;
    align-items: center;
    flex-direction: row;
    background-color: #60bb46;
    border-bottom: 1px solid #c8ced3;
    .default-breadcrumb-home {
        color: white;
        font-size: 13px;
        font-weight: 400;
        padding-right: 10px;
        padding-left: 15px;
        padding-top: 3px;
        padding-bottom: 2px;
        float: left;
        border-radius: 5px;
        background-color: #60bb46;
        i {
            font-size: 20px;
            height: 30px;
            width: 30px;
            border-radius: 200px;
            background: #28a745;
            justify-content: center;
            display: flex;
            align-items: center;
            padding: 18px;
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 0 4px 3px rgba(0, 0, 0, 0.24);
        }
        &:hover {
            cursor: pointer;
            filter: brightness(80%);
            background-color: #60bb46;
        }
    }
    #default-breadcrumb-arrow_id {
        i {
            font-size: 40px;
            color: #8ed67a;
            padding: -1px;
            margin-left: 5px;
            margin-right: 5px;
            text-shadow: 1px 1px 15px #272634c2;
        }
    }
    .default-breadcrumb-item {
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 5px;
        padding-top: 10px;
        float: left;
    }
}
