@import "../assets/scss/defaultTheme.scss";

.default-header {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 55px;
    z-index: 1020;
    display: flex;
    position: fixed;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    justify-content: space-between;
    border-bottom: 1px solid #c8ced3;

    .default-header-logo-container {
        float: left;

        img {
            float: left;
            width: 90px;
            height: 35px;
            margin-left: 12px;
            // margin-top: 8px;

            @media screen and (max-width: 720px) {
                width: 70px !important;
                height: 28px !important;
                margin-left: 8px !important;
            }
        }

        span {
            float: left;
            display: block;
            font-size: 20px;
            padding-left: 5px;
            overflow: hidden;
            color: #585858 !important;
        }

        #main_menu_toggler_id {
            float: left;
            font-size: 25px;
            color: #797979;
            margin-left: 18px;
            text-shadow: 3px 4px 3px #27263452;
            display: none;

            &:hover {
                cursor: pointer;
                font-size: 28px;
                color: #797979;
            }

            @media screen and (max-width: 768px) {
                display: inline;
            }
        }
    }

    #default_header_user_container {
        transition: opacity 0.5s;

        .user-menu-toggler {
            height: 45px !important;
            margin: 0px 10px;
            max-width: 100%;
            border: solid 1px #c3c3c357;
            border-radius: 50em;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);

            &:hover {
                cursor: pointer;
            }

            @media screen and (max-width: 720px) {
                height: 40px !important;
                margin: 0px 5px !important;
            }
        }

        .granted-app-list-toggler {
            float: right;
            font-size: 25px;
            color: #797979;
            margin-right: 15px;
            margin-left: 10px;
            text-shadow: 3px 4px 3px #27263452;

            &:hover {
                cursor: pointer;
                color: #969696;
            }

            @media screen and (max-width: 720px) {
                margin-left: 5px !important;
                margin-right: 10px !important;
            }
        }
    }

    #default_user_menu_id {
        display: none;
        width: 200px;
        height: 186px;
        position: absolute;
        background-color: #cccccc;
        right: 58px;
        top: 50px;
        z-index: 1015;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        @media screen and (max-width: 720px) {
            right: 45px !important;
            width: 150px !important;
            height: 143px !important;
        }

        .user-menu-image-container {
            height: 120px;
            text-align: center;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            background-color: #2f353a;

            @media screen and (max-width: 720px) {
                height: 82px !important;
            }

            img {
                height: 115px;
                margin: 5px 10px;
                max-width: 100%;
                border: solid 1px #c3c3c357;
                border-radius: 50em;

                @media screen and (max-width: 720px) {
                    height: 80px !important;
                }
            }
        }

        .user-menu-full-name {
            border: 0;
            padding: 5px 5px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: white;
            background-color: #2f353a;

            @media screen and (max-width: 720px) {
                font-size: 12px !important;
                font-weight: 300 !important;
            }
        }

        .user-menu-change-password-btn {
            border-radius: 2px;
            padding: 8px 15px;
            text-align: left;
            border: 0;
            height: 35px;
            font-size: 14px;
            font-weight: 400;
            color: white;
            background-color: #959595;

            @media screen and (max-width: 720px) {
                height: 30px !important;
                font-size: 12px !important;
                font-weight: 300 !important;
            }

            &:hover {
                cursor: pointer;
                filter: brightness(90%);
            }
        }

        .user-menu-logout-btn {
            border-radius: 2px;
            margin-bottom: 1px;
            margin-top: 1px;
            padding: 8px 15px;
            text-align: left;
            border: 0;
            height: 35px;
            font-size: 14px;
            font-weight: 400;
            color: white;
            background-color: #d9534f;

            @media screen and (max-width: 720px) {
                height: 30px !important;
                font-size: 12px !important;
                font-weight: 300 !important;
            }

            &:hover {
                cursor: pointer;
                filter: brightness(90%);
            }
        }
    }

    #default_granted_app_menu_id {
        display: none;
        width: 200px;
        height: 300px;
        position: absolute;
        background-color: white;
        right: 10px;
        top: 50px;
        z-index: 1015;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        @media screen and (max-width: 720px) {
            width: 180px !important;
        }

        .default-granted-app-menu-item {
            border-radius: 2px;
            margin-bottom: 1px;
            padding: 8px 15px;
            text-align: left;
            height: 35px;
            font-size: 14px;
            font-weight: 400;
            color: #6c757d;
            background-color: white;
            border-bottom: solid 1px #cccccc;

            @media screen and (max-width: 720px) {
                height: 30px !important;
                font-size: 12px !important;
            }

            &:hover {
                cursor: pointer;
                filter: brightness(90%);
            }
        }
    }
}

/**-- Default menu sidebar --*/
#default_sidebar_id {
    top: 55px;
    margin: 0;
    padding: 0;
    z-index: 1015;
    background-color: #2f353a;
    position: absolute;
    height: calc(100vh - 55px);
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: width 1s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all 0.5s;

}

// #default_sidebar_id.collapsed {
//     margin-left: -250px;
// }

// #default_sidebar_id::-webkit-scrollbar {
//     display: none;
// }

.default-sidebar-navbar {
    font-size: 13px;
    font-weight: 400;
    text-shadow: none;
    letter-spacing: 0.02em;
}

.sidebar-menu-close {
    width: 0;
}

.sidebar-menu-open {
    width: 250px;
}

.nav-item ul {
    margin: 0;
    padding: 0;
}

.nav-item .main-menu {
    display: none;
}

#tm:checked+.main-menu {
    display: block;
}

.nav-item input[type="checkbox"],
.nav-item ul span.drop-icon {
    display: none;
}

.nav-item li,
#toggle-menu,
.nav-item .sub-menu {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
}

.nav-item li,
#toggle-menu {
    border-width: 0 0 1px;
}

.nav-item .sub-menu {
    background-color: #333;
    border-width: 1px 1px 0;
    margin: 0 1em;
}

.nav-item .sub-menu li:last-child {
    border-width: 0;
}

.nav-item,
#toggle-menu {
    background-color: #333;
}

.nav-item a {
    transition: all 0.125s ease-in-out;
    -webkit-transition: all 0.125s ease-in-out;
}

.nav-item a:hover {
    filter: brightness(80%);
    cursor: pointer !important;
}

.nav-title:hover {
    cursor: pointer;
}

.nav-icon {
    font-size: 15px;
    margin-right: 8px;
}

.nav-item .drop-icon {
    line-height: 1;
}

#toggle-menu,
.nav-item a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

#toggle-menu .drop-icon,
.nav-item li label.drop-icon {
    position: absolute;
    right: 0.5em;
    top: 1em;
}

.nav-item li,
#toggle-menu,
.nav-item a {
    position: relative;
    display: block;
    color: white;
    text-decoration: none;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.125);
}

.nav-item label.drop-icon,
#toggle-menu span.drop-icon {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.125);
    text-shadow: 0 0 0 transparent;
    color: rgba(255, 255, 255, 0.67);
}

.nav-item .sub-menu {
    display: none;
}

.nav-item input[type="checkbox"]:checked+.sub-menu {
    display: block;
}

.nav-item li {
    position: relative;
  
    &:hover {
      .sub-menu {
        display: block;
      }
    }
  }
  
  .sub-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px; /* Adjust the width as needed */
    background-color: #2f353a;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .sub-menu li {
    border-width: 0;
  }
  
  .sub-menu a {
    padding: 10px;
    display: block;
    color: white;
    text-decoration: none;
    transition: all 0.125s ease-in-out;
  
    &:hover {
      filter: brightness(80%);
    }
  }

/**-- End of Default menu sidebar --*/

.New-Nav {
    top: 55px;
    margin: 0;
    padding: 0;
    z-index: 1015;
    background-color: #2f353a;
    position: absolute;
    height: calc(100vh - 55px);
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: width 1s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.New-SidebarNav {
    top: 55px;
    margin: 0;
    padding: 0;
    background-color: #2f353a;
    position: absolute;
    height: calc(100vh - 55px);
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: width 2s;
    -ms-overflow-style: none;
    scrollbar-width: none;
   // width: 300px;
}
 
.navIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 5rem;
    font-size: 2rem;
    margin-left: 2rem;
}
