$primaryColor: #51ac37;
$secondaryColor: #5c5c5c;
$warningColor: #f4901f;
$errorColor: #bd2130;

@font-face {
    font-family: "Foco";
    src: url("../fonts/Foco-Regular.ttf");
}
@font-face {
    font-family: "Foco-Bold";
    src: url("../fonts/Foco-Bold.ttf");
}

*{
    margin:0;
    padding:0;
    text-decoration: none;
}

body {
    font-size: 12px;
    font-family: "Foco";
}

/*-- Default font size --*/
html {
    font-size: 16px;
}

@media (max-width: 320px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 321px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 375px) {
    html {
        font-size: 11px;
    }
}
@media (min-width: 768px) {
    html {
        font-size: 11px;
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 14px;
    }
}

// @media (min-width: 1400px) {
//     html {
//         font-size: 14px;
//     }
// }

@media (min-width: 2000px) {
    html {
        font-size: 20px;
    }
}

.btnt {
  background-color: #60bb46 !important;
  margin-bottom: 5px;
}
.btn {
  padding: 6px!important;
  font-size: 12px!important;
}
.btn-block {
  display: block;
  width: 100%;
}
.card-header {
    font-size: 13px;
    font-weight: 450;
    line-height: 8px;
    letter-spacing: .02em;
    color: #fff !important;
    // background-color: #60bb46 !important;
    background-color: #344154 !important;
    padding: 8px 20px !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

//======= Loader =======
.full-width-div {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background: rgba(51, 51, 51, 0.7);
    z-index: 2000;
  }
  .spin-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .spin-container {
    height: 100%;
    width: 100%;
    display: table;
  }
  .spin-inner {
    vertical-align: middle;
    height: 100%;
    display: table-cell;
  }
  .spin-cube-grid {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  .spin-cube {
    width: 33%;
    height: 33%;
    background-color: #fff;
    float: left;
    -webkit-animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
    animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
  }
  .spin-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .spin-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .spin-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .spin-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .spin-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .spin-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .spin-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .spin-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .spin-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  
  @media (max-width: 991.98px)
  {
    .app-header {
      width:  100vw;
  }
  .app-body{
      width:  100vw;
  }
  
  }